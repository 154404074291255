import React, { Component } from 'react'
import moment from 'moment'
import WindHistory from './WindHistory'

class WindListItem extends Component {
  historyOptions() {
    return [ 
      { label: "3 hours", value: "-2" },
      { label: "6 hours", value: "-5" },
      { label: "24 hours", value: "-23" },
      { label: "3 days", value: "-71" },
      { label: "1 week", value: "-167" },
      ]
  }
  speedToColorClass (speed) {
    if (speed < 5) 
      return "speedLessThan5"
    else if (speed <= 8)
      return "speed5to8"
    else if (speed <= 13)
      return "speed9to13"
    else if (speed <= 19)
      return "speed14to19"
    else if (speed <= 29)
      return "speed20to29"
    else if (speed <= 35)
      return "speed30to35"
    else 
      return "speed36plus"
  }

  constructor(props) {
    super(props)
    this.state = {
      historyInterval: this.historyOptions()[0] 
    }
  }

  render() {
      const chartOptions = this.historyOptions().map ( (profile) => { return (  
            <li key={"charthistory-option" + this.props.spot.spot_id + profile.value } className="nav-item">
              <a onClick={ () => { this.setState({ historyInterval: profile}) }}  className={(this.state.historyInterval.value == profile.value ? "nav-link active" : "nav-link") }>
                {profile.label}
              </a>
            </li>
          ) })
      return(<><tr>
        <td>
            <b>{this.props.spot.name}</b><br/>
            <span>{moment.duration(moment(this.props.spot.local_timestamp).diff(moment(this.props.spot.timestamp))).humanize()} ago</span>
          <div className="d-xl-none"><WindHistory offset_hours={this.state.historyInterval.value} ambient={this.props.spot.ambient} spot_id={this.props.spot.spot_id}/></div>
          <ul className="nav nav-pills d-xl-none">
            {chartOptions}
          </ul>
        
        </td>
        </tr>
        <tr>
        <td className="d-none d-xl-table-cell w-75">
          <WindHistory ambient={this.props.spot.ambient} offset_hours={this.state.historyInterval.value} spot_id={this.props.spot.spot_id}/>
          <ul className="nav nav-pills">
            {chartOptions}
          </ul>
        </td>
      </tr></>)
  }
}

export default WindListItem;
