import React, { Component } from 'react'
import moment from 'moment'
import ForecastGraph from './ForecastGraph'

class WindGraph extends Component {
  models(profile_id) {
    return [
    {
      id: "214",
      name: "iK-WRF SoCal",
      profile_ids: [614328, 615166, 655459] 
    },
    {
      id: "211",
      name: "iKitesurf 1km (SF Bay) (WF-WRF) (Premium)",
      profile_ids: [619897],
    },
    {
      id: "229",
      name: "iK-WRF Canada (Premium)",
      profile_ids: [676019]
    },
    {
      id: "170",
      name: "HRRR 3km (Hi-Res Rapid Refresh) (Premium)",
      profile_ids: [676019, 615166, 655459, 614328, 635905, 619897] 
    },
    {
      id: "-1",
      name: "Blended (Free)",
      profile_ids: [676019, 615166, 655459, 614328, 635905, 619897, 681799]
    },
    ].filter(x => x.profile_ids.includes(profile_id))
  }
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      value: 0,
      isLoaded: false,
      activeModel: this.models(this.props.profile_id)[0],
      data: {},
      loaded_spot_ids: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.spot_ids.length != this.props.spot_ids.length)
      this.loadModelData()
    if (prevState.activeModel.id != this.state.activeModel.id)
      this.loadModelData()
  }

  componentDidMount() {
    this.loadModelData()
  }

  loadModelData() {
    const onlyUnique = function(value, index, self) {
      return self.indexOf(value) === index;
    }
    this.props.spot_ids.map( (spot_id) => {
      fetch("https://jw-cors-anywhere.herokuapp.com/https://api.weatherflow.com/wxengine/rest/model/getModelDataBySpot?units_wind=kts&units_temp=f&units_distance=mi&spot_id=" + spot_id + "&model_id=" + this.state.activeModel.id + "&wf_token=8423bcf8ae25cf85ff473614a121e6b5&_=1641914039249")
        .then(res => res.json())
        .then(
          (result) => {
            var sequence = []
            for (let index = 0; index < result.model_data.length; index++) {
              var projection = {}
              projection.wind_speed = result['model_data'][index].wind_speed
              projection.wind_gust = result['model_data'][index].wind_gust
              projection.wind_dir = result['model_data'][index].wind_dir_txt
              projection.wind_dir_val = result['model_data'][index].wind_dir
              projection.timestamp = result['model_data'][index].model_time_local
              sequence.push(projection)
            }
            const newData = Object.assign({}, this.state.data)
            const name = this.props.spotIdToName[spot_id]
            newData[spot_id] = { sequence, name }
            this.setState({
              isLoaded: true,
              data: newData,
              loaded_spot_ids: this.state.loaded_spot_ids.concat(spot_id).filter(onlyUnique)
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      // Load tide information
      const lat_lng = this.props.spotIdToLatLng[spot_id]
      fetch("https://jw-cors-anywhere.herokuapp.com/https://api.weatherflow.com/wxengine/rest/spot/getSpotSetBySearch?units_wind=kts&units_temp=f&units_distance=mi&units_precip=in&spot_types=10,18&search_dist=20&stormprint_only=false&search=" + lat_lng.lat+':' +lat_lng.lng + "&wf_token=8423bcf8ae25cf85ff473614a121e6b5&_=1671765548482")
        .then(res => res.json())
        .then(
          (result) => {
            if (result['data_values'].length == 0)
              return;
            const stationName = result['data_values'].filter((d) => {return d[result['data_names'].indexOf('type')] == 10})[0][result['data_names'].indexOf('name')]
            const formatDateOffset = (_date, offset) => { let date = new Date(_date.getTime() + offset*24*60*60*1000); return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate()) + "+00:00:00" }
            const d_now =  formatDateOffset((new Date()), -1) 
            const d_5now =  formatDateOffset((new Date()), 10) 
            fetch('https://jw-cors-anywhere.herokuapp.com/https://ww.windalert.com/cgi-bin/tideJSON?tideSiteName=' + stationName + '&beginTime=' + d_now + '&endTime=' + d_5now)
              .then(res => { return res.json() } )
              .then(
                (result) => {
                  const newData = Object.assign({}, this.state.data)
                  if (!newData[spot_id])
                    newData[spot_id] = {}
                  newData[spot_id].tideEvents = result.tideEvents.filter( (d) => { return d.eventName == 'Low Tide' || d.eventName == 'High Tide' } );
                  this.setState({
                    data: newData
                  });
                }
              )
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    })
  }

  componentWillMount() {
  }
  speedToColorClass (speed) {
    if (speed < 5) 
      return "speedLessThan5"
    else if (speed <= 8)
      return "speed5to8"
    else if (speed <= 13)
      return "speed9to13"
    else if (speed <= 19)
      return "speed14to19"
    else if (speed <= 29)
      return "speed20to29"
    else if (speed <= 35)
      return "speed30to35"
    else if (speed) 
      return "speed36plus"
  }
  
  render() {
    const header = this.state.loaded_spot_ids.slice(0,1).map((spot_id) => { 
      if (!this.state.data[spot_id]) {
        return <></>
      }
      return ( 
        <tr key={"header-" + spot_id}><td className="tab "> </td> {
          this.state.data[spot_id].sequence.map((element) => {
            return (
              <td key={"time"+ element.timestamp}>
                <table>
                  <tr><th className="p-1 timecol">{moment(element.timestamp).format("ddd ha")}</th></tr>
                </table>
              </td>)
          })}
        </tr>)
      })
    const dirArrow = (dir, width) => { return <img className="m-0" src="https://www.pinclipart.com/picdir/big/43-431770_clip-arts-related-to-down-arrow-mark-png.png" style={{"height": width + "px", "width": width + "px", "transform": "rotate(" + dir + "deg)"}}/> }

    const extrapolateTide = (timestamp, spot_id) => { 
      const tideEvents = this.state.data[spot_id].tideEvents
      const parseDate = (d) => { return Date.parse(d.replaceAll("-","/")) }
      const before = tideEvents.filter((d) => parseDate(d.dateTime) < Date.parse(timestamp)).pop()
      const after = tideEvents.filter((d) => parseDate(d.dateTime) >= Date.parse(timestamp))[0]
      if (!before || !after)
        return 'err'
      const extractValue = (d) => { return parseFloat(d.eventValue.split(" ft")[0]) }
      const before_v = extractValue(before)
      const after_v = extractValue(after)
      return (before_v  + ((after_v - before_v)*(Date.parse(timestamp) - parseDate(before.dateTime))/(parseDate(after.dateTime) - parseDate(before.dateTime)))).toFixed(1) + ' ft';
    }
    const series = this.state.loaded_spot_ids.map((spot_id,ind) => { 
      if (!this.state.data[spot_id]) {
        return <></>
      }
      return ( 
                <>
                { ind % 3 == 0 && header }
        <tr className="spot" key={"series-" + spot_id}><td className="tab headcol" key={"tab-" + spot_id}><br /><b>{this.state.data[spot_id].name}</b></td>{
          this.state.data[spot_id].sequence.map((element) => {
            return (
              <td key={"wind-history-"+spot_id+element.timestamp} className="text-center">
                <table className="w-100">
                  <tr><td key={"wind-speed-" + spot_id + element.timestamp} className={"speedbox " + this.speedToColorClass(element.wind_speed)}>{element.wind_speed}</td></tr>
                  <tr><td key={"wind-gust-" + spot_id + element.timestamp} className={"speedbox " + this.speedToColorClass(element.wind_gust)}>{element.wind_gust}</td></tr>
                  <tr><td className="speedbox " key={"wind-dir-" + spot_id + element.timestamp}> {element.wind_dir} {dirArrow(element.wind_dir_val, 8)}</td></tr>
                  { this.state.data[spot_id].tideEvents &&
                    <tr><td className="speedbox " key={"tide-" + spot_id + element.timestamp}> {extrapolateTide(element.timestamp, spot_id)}</td></tr>
                  }
                </table>
              </td>)
          })}
        </tr></>)
      })
    const modelOptions = this.models(this.props.profile_id).map ( (model) => { return (  
          <li key={"model-" + model.id} className="nav-item">
            <a onClick={ () => { this.setState({ activeModel: model }) }}  className={(this.state.activeModel.id == model.id ? "nav-link active" : "nav-link") }>
              {model.name}
            </a>
          </li>
        ) })
    return (
    <div className="container-fluid">
      <div className="row">
        <div className="page-breadcrumb bg-white border-bottom">
          <div className="page-title">
            <h4>Forecasts</h4>
          </div>
        </div>
        <div className="white-box m-3-lg">
          <ul className="nav nav-pills">
            {modelOptions}
          </ul>
        </div>
        <div className= "white-box m-3-lg d-none">
          <h4>Forecast graph</h4>
          <ForecastGraph data={this.state.data} spot_ids={this.state.loaded_spot_ids} />
        </div>
        <div className="white-box m-3-lg">
          <h4>Forecast table</h4>
          <div className="tscroll">
            <table className="table">
              <tbody>
                {series}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>)
  }

}
export default WindGraph;



