import React, { Component } from 'react'
class AlertElement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: localStorage.getItem("email"),
      token: null,
      isSelected: false,
      uploading: false,
      page_data: [],
      has_account: localStorage.getItem("email") || null,
      interval: "900",
      active_spot_id: null,
      running: false,
      other_spot_ids: []
    }
  }

  handleIntervalChange = (event) => {
    this.setState({ interval: event.target.value })
  }
  
  handleSetActiveSpot= (event) => {
    this.setState({ active_spot_id: event.target.value, other_spot_ids: [] })
  }

  handleCheck = (event) => {
    var updatedList = [...this.state.other_spot_ids]
    if (event.target.checked) {
      updatedList = [...updatedList, event.target.value]
    } else {
      updatedList.splice(updatedList.indexOf(event.target.value), 1);
    }
    this.setState({ other_spot_ids: [...new Set(updatedList)] })
  }

  valid_token(string) {
    if (!string)
      return false
    return string.length > 20
  }
  valid_email(string) {
    if (!string)
      return false
    return string.split("@").length == 2
  }

  handleEmailChange = (event) => {
		this.setState({ 
      email: event.target.value,
    })
	};
 

  handleTokenChange = (event) => {
		this.setState({ 
      token: event.target.value,
    })
	};

  componentDidMount() {
    if (this.state.email) {
      this.handleAlreadyHaveAccount()
    }
  }

  handleStopAlerts = () => {
    const url = 'https://jw-cors-anywhere.herokuapp.com/http://44.202.69.30:8080/stop?' + new URLSearchParams({ email: this.state.email })
    fetch(url, { method: "POST" }).
      then((result) => {
        this.setState({ running: false })
      })
  }
  
  handleStartAlerts = () => {
    const spot_ids = [this.state.active_spot_id, ...this.state.other_spot_ids].join(",")
    debugger;
    const url = 'https://jw-cors-anywhere.herokuapp.com/http://44.202.69.30:8080/start?' + new URLSearchParams({ email: this.state.email, spot_ids: spot_ids, interval_s: this.state.interval })
    fetch(url, { method: "POST" }).
      then((result) => {
        this.setState({ running: true })
      })
  }

  handleAlreadyHaveAccount =() => {
    const url = 'https://jw-cors-anywhere.herokuapp.com/http://44.202.69.30:8080/has_account?' + new URLSearchParams({ email: this.state.email }) 
    fetch(url, { method: "GET" }).
      then((response) => response.json())
      .then((result) => {
        if (result['has_account']) {
          localStorage.setItem("email", this.state.email)
          result['active'] = result['active'] || {}
          result['active']['spot_ids'] = result['active']['spot_ids']  || ""
          result['active']['interval_s'] =  result['active']['interval_s'] || "900" 
          this.setState({ has_account: true, running: result.active.spot_ids[0] != null, active_spot_id: result.active.spot_ids[0], other_spot_ids: result.active.spot_ids.slice(1), interval: result.active.interval_s })
        }
        else {
          this.setState({ has_account: false, running: false })
        }
      })
  }

	handleAccountCreation = () => {
    this.setState({ uploading: true })
    const url = 'https://jw-cors-anywhere.herokuapp.com/http://44.202.69.30:8080/set_token?' + new URLSearchParams({ email: this.state.email, pushover_token: this.state.token }) 
    
    fetch(
			url,
			{
				method: 'POST',
			}
		)
			.then((response) => response.json())
			.then((result) => {
        localStorage.setItem("email", this.state.email)
        this.setState({ has_account: true })
        this.setState({ uploading: false, page_data: result['pages']})
				console.log('Success:', result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

  render() {
    const isSelected = this.state.isSelected
    const email = this.state.email
    const token = this.state.token
    const has_account = this.state.has_account
    const spots = [ { id: "422", name: "PIER / (Tourmaline)"}, 
      { id: "458", name: "M.B. / (Mission Bay)" },
      { id: "421", name: "STND / (Silver Strand)" },
      { id: "93759", name: "JETT / (North Jetty South Mission Beach)" },
      { id: "556795", name: "SAIL / (Sail Bay)" },
      { id: "93475", name: "OSIDE / (Oceanside" }]
    const active_spot_options = spots.map((spot) => { 
      return (<>
        <option checked={ this.state.active_spot_id == spot.id } value={spot.id}>{spot.name}</option>
      </>)
    })
    const spot_options = spots.filter((spot) => { return spot['id'] != this.state.active_spot_id }).map((spot) => { 
      return (<>
        <div className="form-check">
          <input checked={this.state.other_spot_ids.includes(spot.id)} value={spot.id} onChange={this.handleCheck} className="form-check-input" type="checkbox" id={"spot-" + spot.id} />
          <label className="form-check-label">
            {spot.name} 
          </label>
        </div>
      </>)
    })
    return (
    <div className="bg-light">
      <div className="p-3">
          <div className="card col-12 offset-0 col-lg-4 offset-lg-4">
            <div className="card-header text-center">
              {!this.state.has_account &&
                <h5>Register for notifications</h5>
              }
              {this.state.has_account &&
                <h5>Let's stay in the LOOP</h5>
              } 
            </div>
            <div className="card-body">
              {this.state.has_account && this.state.running !=null && 
              <div >
                <h5> 1. Where are you kiting?</h5>
                <select onChange={this.handleSetActiveSpot} value={this.state.active_spot_id} className="form-select">
                  <option></option>
                  {active_spot_options}          
                </select>
                {this.state.active_spot_id &&
                  <>
                    <h5 className="mt-3"> 2. Which other spots would you like to be notified about?</h5>
                    {spot_options}          
              <h5 className="mt-3">3. Alert Frequency:</h5>
              
              <select className="form-select" value={this.state.interval} onChange={this.handleIntervalChange} >
                <option value={"300"}>Every 5 minutes</option>
                <option value={"600"}>Every 10 minutes</option>
                <option value={"900"}>Every 15 minutes</option>
                <option value={"1200"}>Every 20 minutes</option>
                <option value={"1500"}>Every 25 minutes</option>
                <option value={"1800"}>Every 30 minutes</option>
                <option value={"2700"}>Every 45 minutes</option>
                <option value={"3600"}>Every 1 hour</option>
                <option value={"7200"}>Every 2 hours</option>
                <option value={"10800"}>Every 3 hours</option>
              </select>
                <hr />
                <button disabled={!this.valid_email(email)} className="btn-lg w-100 btn btn-success mb-2" onClick={this.handleStartAlerts}>{this.state.running ? "Update" : "Start"} Alerts</button>
                {this.state.running &&
                  <button disabled={!this.valid_email(email)} className="btn-lg w-100 btn btn-danger" onClick={this.handleStopAlerts}>Stop Alerts</button>
                }
                 </>
                }
              </div>
              }
              {!this.state.has_account &&
                <>
                  <div className="form-group">
                    <label>Email or username:</label>
                    <input placeholder="Enter your email" type="input" name="email" className="form-control" onChange={this.handleEmailChange} />
                    <small>Just don't forget it.</small>
                  </div>
                  { this.state.has_account == false &&
                    <>
                      <label className="mt-3">Pushover token:</label>
                      <input type="input" name="pushover_token" className="form-control" onChange={this.handleTokenChange} />
                      <small>We use the Pushover app to send notifications. <a target="__blank" href="https://pushover.net/">Click here to download it</a>. You'll need to copy and paste your user token above.</small>
                      <button disabled={!this.valid_token(token)} className="btn btn-primary m-2 w-100" onClick={this.handleAccountCreation}>Create Account</button>
                    </>
                  }
                  { this.state.has_account != false &&
                      <button disabled={!this.valid_email(email)} className="btn btn-primary m-2 w-100" onClick={this.handleAlreadyHaveAccount}>Next</button>
                  }
                  <br />
                </>
              }
            </div>  
          </div>
        <div>
        </div>
      </div>
    </div>)
  }
}

export default AlertElement;
