import React, { Component } from 'react'
import moment from 'moment'
import { ResponsiveContainer, LineChart, YAxis, CartesianGrid, Line, XAxis, Tooltip, Legend} from 'recharts'

class ForecastGraph extends Component {
  modes() {
    return ['avg', 'gust']
  }
  durations () {
    return [
      { label: '1 day', value: 86400000  },
      { label: '2 day', value: 86400000*2 },
      { label: '3 day', value: 86400000*3 },
      { label: '5 day', value: 86400000*5 },
      { label: '1 week ', value: 86400000*7 },
    ]
  }
  constructor(props) {
    super(props)
    this.state = {
      mode: ['avg'],
      duration: this.durations()[3]
    }
  }
  render() {
    if (!this.props.spot_ids || this.props.spot_ids.length == 0)
      return <> </>
    const total_samples = Math.max(this.props.data[this.props.spot_ids[0]].sequence.length - 1, 0) 
    const first_ts = total_samples == 0 ? 0 : this.props.data[this.props.spot_ids[0]].sequence[0].timestamp 
    const last_ts = total_samples == 0 ? 0 : this.props.data[this.props.spot_ids[0]].sequence[total_samples -1].timestamp
    const num_samples = total_samples == 0 ? 0 : parseInt(Math.min(total_samples, 1 + this.state.duration.value / ((moment(last_ts).diff(first_ts))/total_samples)))
    const data = [...Array(num_samples)].map((_, index) => { 
      var data_pt = { }
      this.props.spot_ids.map((spot_id) => {
        const spot_data = this.props.data[spot_id]
        const d_key = this.state.mode == 'avg' ? 'wind_speed' : 'wind_gust'
        if (spot_data.sequence && spot_data.sequence[index]) { 
          data_pt[spot_data.name] = spot_data.sequence[index][d_key]
          data_pt['timestamp'] = moment(spot_data.sequence[index]['timestamp']).format("ddd ha")
        }
      })
      return data_pt;
    })
    const durationOptions = this.durations().map ( (duration) => { return (  
          <li key={"duration-" + duration.value } className="nav-item">
            <a onClick={ () => { this.setState({ duration: duration }) }}  className={(this.state.duration.value == duration.value ? "nav-link active" : "nav-link") }>
              {duration.label}
            </a>
          </li>
        ) })
    const modeOptions = this.modes().map ( (mode) => { return (  
          <li key={"mode-" + mode } className="nav-item">
            <a onClick={ () => { this.setState({ mode: mode}) }}  className={(this.state.mode == mode ? "nav-link active" : "nav-link") }>
              {mode}
            </a>
          </li>
        ) })
    const colors = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
    const lines = this.props.spot_ids.map((spot_id, index) => {
      return <Line type="monotone" dataKey={this.props.data[spot_id].name} dot={false} stroke={colors[index]} strokeWidth={"2px"} />
    })
    if (data.length > 0) {
      const canvas = <LineChart data={data} margin={{left: -30}} >
          <XAxis dataKey="timestamp" tick={{ fill: 'black' }} />
          <YAxis/>
          <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
          <Tooltip margin={{top: 30 }} />
          <Legend />
          {lines}
      </LineChart>
      return (<div style={{ "overflow-x": "scroll" }} > 
      <ResponsiveContainer width="100%" height={300}>
        { canvas }
      </ResponsiveContainer>
        <div className="white-box m-3-lg">
          <ul className="nav nav-pills">
            {modeOptions}
          </ul>
          <ul className="nav nav-pills">
            {durationOptions}
          </ul>
        </div>
      </div>) 
    }
    return <></>
  }
}

export default ForecastGraph;
