import React, { Component } from 'react'
import WindGraph from './WindGraph'
import WindMap from './WindMap'
import WindListItem from './WindListItem'
import moment from 'moment'

class WindList extends Component {
  profiles() {
    return [
    {
      name: "SD",
      id: 615166,
      center: {
        lat: 32.685736,
        lng: -117.161087,
        zoom: 11
      },
      //ambient_ids: ["65b5860fea4e0e5a6f9306c4f98c50e6"]
    },
    {
      name: "Long Beach",
      id: 655459,
      center: {
        lat:  33.65722,
        lng: -118.13611, 
        zoom: 12
      }
    },
    {
      name: "SoCal / SD",
      id: 614328, 
      center: {
        lat: 33.465889,
        lng: -117.630368,
        zoom: 8
      }
    },
    {
      name: "Canada",
      id: 676019,
      center: {
        lat: 49.446292, 
        lng: -123.116226,
        zoom: 10
      }
    },
    {
      name: "Baja",
      id: 635905,
      center: {
        lat: 24.046476, 
        lng: -109.994347,
        zoom: 13
      }
    },
    {
      name: "Bay Area",
      id: 619897,
      center: {
        lat: 37.774929,
        lng: -122.419418,
        zoom: 10
      }
    },
    {
      name: "Italy",
      id: 681799,
      center: {
        lat: 41.902782, 
        lng: 12.496366, 
        zoom: 5
      }
    },
    ]
  }
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      spots: [],
      activeSpotIds: [],
      activeProfile: this.profiles()[0],
    };
  }
  
  speedToColorClass (speed) {
    if (speed < 5) 
      return "speedLessThan5"
    else if (speed <= 8)
      return "speed5to8"
    else if (speed <= 13)
      return "speed9to13"
    else if (speed <= 19)
      return "speed14to19"
    else if (speed <= 29)
      return "speed20to29"
    else if (speed <= 35)
      return "speed30to35"
    else 
      return "speed36plus"
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeProfile.id != this.state.activeProfile.id)
      this.loadProfileData()
  }

  componentDidMount() {
    this.loadProfileData()
  }
  loadProfileData() {
    this.setState({
      isLoaded: false,
      spots: [],
      activeSpotIds: [],
      graphSpotId: null
    });
    fetch("https://jw-cors-anywhere.herokuapp.com/https://api.weatherflow.com/wxengine/rest/spot/getSpotDetailSetByProfile?units_wind=kts&units_temp=f&units_distance=mi&spot_types=1%2C100%2C101&sort=windspeed&stormprint_only=false&profile_id=" + this.state.activeProfile.id +"&wf_token=8423bcf8ae25cf85ff473614a121e6b5&_=1662569556227")
      .then(res => res.json())
      .then(
        (result) => {
          var spots = []
          for (let index = 0; index < result.spots.length; index++) {
            let keys = result["spots"][index]["data_names"]
            var spot_d = {}
            spot_d.lull = result["spots"][index]["stations"][0]["data_values"][0][keys.indexOf("lull")]
            spot_d.spot_id = result['spots'][index].spot_id
            spot_d.avg = result["spots"][index]["stations"][0]["data_values"][0][keys.indexOf("avg")]
            spot_d.gust = result["spots"][index]["stations"][0]["data_values"][0][keys.indexOf("gust")]
            spot_d.dir = result["spots"][index]["stations"][0]["data_values"][0][keys.indexOf("dir_text")]
            spot_d.dir_val = result["spots"][index]["stations"][0]["data_values"][0][keys.indexOf("dir")]
            spot_d.timestamp = result["spots"][index]["stations"][0]["data_values"][0][keys.indexOf("timestamp")]
            spot_d.local_timestamp =  result["spots"][index]['current_time_local']
            spot_d.name = result["spots"][index]["name"]
            spot_d.lat = result["spots"][index]["lat"]
            spot_d.lng = result["spots"][index]["lon"]
            spots.push(spot_d)
          }

          return spots
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
      .then( (spots) => {
        if (this.state.activeProfile && this.state.activeProfile.ambient_ids) {
          let ambient_id = this.state.activeProfile.ambient_ids[0] 
          fetch("https://jw-cors-anywhere.herokuapp.com/https://lightning.ambientweather.net/devices?public.slug=" + ambient_id)
            .then(res => res.json())
            .then(
              (result) => {
                var _spots = spots
                let data = result['data'][0]['lastData']
                let info = result['data'][0]['info']
                spots.push( 
                  {
                    "avg": data['windspeedmph'], 
                    "gust": data['windgustmph'], 
                    "timestamp": data['dateutc5'],
                    "name": info['name'],
                    'lat': info['lat'],
                    'lng': info['lon'],
                    'dir_val': data['winddir'],
                    'ambient': true,
                    'spot_id': ambient_id
                    })
                
                this.setState({
                  isLoaded: true,
                  spots: spots.sort((a, b) => (a.avg > b.avg) ? -1 : 1),
                  graphSpotId: spots[0].spot_id,
                  activeSpotIds: spots.map((spot) => { return spot.spot_id } )
                });
            })
        }
        else {
          this.setState({
            isLoaded: true,
            spots: spots,
            graphSpotId: spots[0].spot_id,
            activeSpotIds: spots.map((spot) => { return spot.spot_id } )
          });
        }
      })
  }
  speedToColorClass (speed) {
    if (speed < 5) 
      return "speedLessThan5"
    else if (speed <= 8)
      return "speed5to8"
    else if (speed <= 13)
      return "speed9to13"
    else if (speed <= 19)
      return "speed14to19"
    else if (speed <= 29)
      return "speed20to29"
    else if (speed <= 35)
      return "speed30to35"
    else 
      return "speed36plus"
  }
  componentWillMount() {
  }
  render() {
    const spotsList = this.state.spots.map((spot) =>  { return (
      <WindListItem spot={spot} />
    ) })
    const dirArrow = (dir, width) => { return <img className="m-2" src="https://www.pinclipart.com/picdir/big/43-431770_clip-arts-related-to-down-arrow-mark-png.png" style={{"height": width + "px", "width": width + "px", "transform": "rotate(" + dir + "deg)", "z-index": 100}}/> }
    const spotsList2 = this.state.spots.map((spot) =>  { return (
      <tr className={this.state.graphSpotId == spot.spot_id ? "table-warning" : ""} onClick={() => { this.setState({ graphSpotId: spot.spot_id })}} >
        <td className="spot-name">
          <div className={"p-3 " + this.speedToColorClass(spot.avg)}>
            {spot.avg}{dirArrow(spot.dir_val, 15) }
          </div>
        </td>
        <td>
          <b>{spot.name.slice(0,20)}</b>
          <br/>
          {spot.avg} ({spot.lull}-{spot.gust}) {spot.dir} 
          <br/>
          <span>{moment.duration(moment(spot.local_timestamp).diff(moment(spot.timestamp))).humanize()} ago</span>
        </td>
      </tr>
    ) })
    var spotIdToName = {}
    this.state.activeSpotIds.map((spot_id) => { spotIdToName[spot_id] = this.state.spots.filter( (spot) => { return spot.spot_id == spot_id } )[0].name})
    var spotIdToLatLng = {}
    this.state.activeSpotIds.map((spot_id) => { const spot_d =  this.state.spots.filter( (spot) => { return spot.spot_id == spot_id } )[0]; spotIdToLatLng[spot_id] = { lat: spot_d.lat, lng: spot_d.lng }})
    const profileOptions = this.profiles().map ( (profile) => { return (  
          <li key={"profile-" + profile.id} className="nav-item">
            <a onClick={ () => { this.setState({ activeProfile: profile}) }}  className={(this.state.activeProfile.id == profile.id ? "nav-link active" : "nav-link") }>
              {profile.name}
            </a>
          </li>
        ) })
    const graphSpot = this.state.spots.filter((spot) => spot.spot_id == this.state.graphSpotId )[0]
    return (<div className="m-2">
      { this.state.isLoaded && 
       <div className="container-fluid">
          <div className="row">
            <div className="page-breadcrumb bg-white border-bottom">
              <div className="page-title">
                <h4>Spots Summary </h4>
                <h4><span className="badge btn-danger">Note: all wind speeds now in kts</span></h4>
              </div>
            </div>
            <div className="white-box m-3-lg">
              <ul className="nav nav-pills">
                {profileOptions}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="white-box m-3-lg col-lg-4">
              <table className="table table-hover">
                <thead>
                  <tr> 
                    <th className="spot-name d-none d-xl-table-cell"></th>
                    <th className="spot-wind d-none d-xl-table-cell"></th>
                  </tr>
                </thead>
                <tbody className="border-top-0">
                  { spotsList2 }
                </tbody>
              </table>
            </div>
            <div className="white-box m-3-lg col-lg-8">
              <table className="w-100">
              <WindListItem spot={graphSpot} />
              </table>
            <WindMap center={this.state.activeProfile.center} spots={this.state.spots} spot_ids={this.state.activeSpotIds} spotIdToName={spotIdToName} spotIdToLatLng={spotIdToLatLng} />
            </div>
          </div>
        </div>
      }
    { this.state.activeSpotIds.length > 0 && 
      
      <>
      {<WindGraph spots={this.state.spots} profile_id={this.state.activeProfile.id} spot_ids={this.state.activeSpotIds} spotIdToName={spotIdToName}  spotIdToLatLng={spotIdToLatLng} /> }
      </> 
    }
    </div>);
  }

}
export default WindList;
