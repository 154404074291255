import React, { Component } from 'react'
import moment from 'moment'
import { ReferenceDot, Tooltip, Legend, ResponsiveContainer, LineChart, YAxis, CartesianGrid, Line, XAxis} from 'recharts'
class WindHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      avg_data: [],
      lull_data: [],
      gust_data: []
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.offset_hours != prevProps.offset_hours)
      this.loadGraph()
    if (this.props.spot_id != prevProps.spot_id)
      this.loadGraph()
  }
  loadGraph() {
    const date_tick_format = function (epoch) { return moment(epoch).format("ddd h:mm a")}
    this.setState({
      isLoaded: false,
      avg_data: [],
      lull_data: [],
      gust_data: [],
      dir_data: []
    });
    if (this.props.ambient) {
      let time_now = new Date().getTime() + 60*5
      let time_start = time_now + this.props.offset_hours * 60 *60 *1000
      fetch("https://lightning.ambientweather.net/device-data?macAddress=24%3AD7%3AEB%3ADB%3A18%3AE1&start=" + time_start + "&end=" + time_now + "&limit=2000&dataKey=graphDataRefined")
        .then(res => res.json())
        .then(
          (result) => {
            var data = result['data']
            data.reverse()
            const avg_data = (data).map((d) => { return {x: date_tick_format(d['dateutc']), y: d['windspeedmph'] } })
            const gust_data = (data).map((d) => { return {x: date_tick_format(d['dateutc']), y: d['windgustmph'] } })
            const wind_dir_data = (data).map((d) => { return {x: date_tick_format(d['dateutc']), y: d['winddir'] } })
            this.setState({ isLoaded: true, dir_data: wind_dir_data, avg_data: avg_data, gust_data: gust_data, lull_data: {} }) 
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
    else {
      fetch("https://jw-cors-anywhere.herokuapp.com/https://api.weatherflow.com/wxengine/rest/graph/getGraph?units_wind=kts&units_temp=f&units_distance=mi&fields=wind&format=json&null_ob_min_from_now=30&show_virtual_obs=true&spot_id=" + this.props.spot_id  + "&time_start_offset_hours="+ this.props.offset_hours + "&time_end_offset_hours=4&type=dataonly&model_ids=-101&wf_token=8423bcf8ae25cf85ff473614a121e6b5&_=1641956032339")
        .then(res => res.json())
        .then(
          (result) => {
            const avg_data = (result['wind_avg_data'] || []).map((d) => { return {x: date_tick_format(d[0]), y: d[1] } })
            const gust_data = (result['wind_gust_data'] || []).map((d) => { return {x: date_tick_format(d[0]), y: d[1] } })
            const lull_data = (result['wind_lull_data'] || []).map((d) => { return {x: date_tick_format(d[0]), y: d[1] } })
            const wind_dir_data = (result['wind_dir_data'] || []).map((d) => { return {x: date_tick_format(d[0]), y: d[1] } })
            this.setState({ isLoaded: true, dir_data: wind_dir_data, avg_data: avg_data, gust_data: gust_data, lull_data: lull_data }) 
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
    }
  }
  componentDidMount() {
    this.loadGraph()
  }
  componentWillMount() {
  }
  render() {
    const num_samples = Math.max(this.state.avg_data.length - 1, 0) 
    const data = [...Array(num_samples)].map((_, index) => { return { x: (this.state.avg_data[index] || {}).x, avg: (this.state.avg_data[index] || {}).y, dir: (this.state.dir_data[index] || {}).y, gust: (this.state.gust_data[index] || {}).y, lull: (this.state.lull_data[index] || {}).y} }).filter((x) => x.dir !== undefined ) 
    const max = Math.ceil(Math.max.apply(Math, data.map((d) => { return Math.max(d.avg || 0, d.gust || 0 ) })))
    const min = Math.floor(Math.min.apply(Math, data.map((d) => { return Math.min(d.avg || 90, d.lull|| 90 ) }))) 
    const dirs = data.map((pt, index) => { return <ReferenceDot x={pt['x']} y={max+1} r={10} fill ='none' stroke={"none"} 
      label={ { content: (external) => { 
        const rot = pt['dir'];
        return <image className="m-2" href="https://www.pinclipart.com/picdir/big/43-431770_clip-arts-related-to-down-arrow-mark-png.png" x={external.viewBox.x} y={external.viewBox.y} style={{ "x": external.viewBox.x, "y": external.viewBox.y, "height": 20 + "px", "transform-origin": (external.viewBox.x + 10) + "px " + (external.viewBox.y  + 10) + "px", "width": 20 + "px", "transform": "rotate(" + rot + "deg)", "z-index": 100}}/> }
        } }  /> })
    if (data.length > 0 && this.state.isLoaded) {
      const canvas = <LineChart data={data} margin={{left: -30}} >
          <XAxis dataKey="x" tick={{ fill: 'black' }} />
          <YAxis domain={[min,max+3]} ticks={ (max < 20 ? [0,3,6,9, 12,15,18,21] : [0,5,10,15,20,25,30,35,40]).filter((x) => {return x < max + 2 && x >= min})} /> 
          <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
          <Line type="monotone" dataKey="gust" dot={false} stroke="#ff0000" strokeWidth={"2px"} />
          <Line type="monotone" dataKey="avg" dot={false} stroke="#0000ff" strokeWidth={"2px"} />
          <Line type="monotone" dataKey="lull" dot={false} stroke="#2e8a06" strokeWidth={"2px"} />
          { dirs.filter(function(value, index, Arr) { return index % (Math.floor(data.length / 20) + 1) == 0 }) }
          <Tooltip margin={{top: 30 }} />
          <Legend />
      </LineChart>
      return (<div style={{ "overflow-x": "scroll" }} > 
      <ResponsiveContainer width="100%" height={300}>
        { canvas }
      </ResponsiveContainer>
      
      </div>) 
    }
    return <></>
  }

}
export default WindHistory;

