import React, { Component } from 'react'
import WindGraph from './WindGraph'
import WindListItem from './WindListItem'
import moment from 'moment'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'
class WindMap extends Component {
  constructor(props) { 
    super(props)
    this.state = {
      showMap: false
    }
  }
  render() {
    console.log(this.props.spotIdToLatLng);
    var spot_ids_to_spot = {}
    this.props.spots.map((spot) => spot_ids_to_spot[spot.spot_id] = spot )
    const spots = this.props.spot_ids.map((spot_id) => {
      const spot = spot_ids_to_spot[spot_id]
      const dirArrow = (dir, width) => { return <img className="m-2" src="https://www.pinclipart.com/picdir/big/43-431770_clip-arts-related-to-down-arrow-mark-png.png" style={{"height": width + "px", "width": width + "px", "transform": "rotate(" + dir + "deg)", "z-index": 100}}/> }
      if (!spot || !this.props.spotIdToLatLng[spot.spot_id])
        return <></>
      return <Marker 
    key={spot.id}
    lat={this.props.spotIdToLatLng[spot.spot_id].lat}
    lng={this.props.spotIdToLatLng[spot.spot_id].lng}
    text={<>{dirArrow(spot.dir_val, 60)}<div style={{ position: 'absolute', left: '25px', top: '28px' }}>{spot.avg}</div> </>}
    altText={spot.dir_val}
    />})

    return (<>
      { this.state.showMap && 
      <>
        <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCG2gpuETVba2w4gxYvlSu7Z6Hb8Afzcs0' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.center.zoom}
        >
          {spots}
        </GoogleMapReact>
        </div>
      </>
      }
      { !this.state.showMap && 
        <div className="text-center border p-3 mt-4" width="100%" >
        <a onClick={ () => { this.setState({ showMap: true }) }} className="btn btn-success btn-lg">
          Load Map
        </a>
        </div>
      }
    </>);
  }
}
export default WindMap;
