import React, { Component } from 'react'
import WindList from './WindList'
import AlertElement from './AlertElement'
import './App.css' 
import './style.min.css' 
import './LuckiestGuy-Regular.ttf'
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
  NavLink
} from "react-router-dom";
class App extends React.Component {
    render () {
        return(
          <Protect sha512='85221d15b673dfb4c45c75cd7e430f7e5f79ae1805ddaceb59789de7cb93d4256f1e8551c7a9d4d7d0f697d089d5c789a8feb751a4c1175c14dbfce67fc821e8' >
          <>
              <Router>
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
              integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
              crossorigin="anonymous"
            />
            <header className="topbar" data-navbarbg="skin5">
            <nav className="navbar top-navbar bg-dark navbar-expand navbar-dark">
                <div className="navbar-header" data-logobg="skin6">
                    <a className="navbar-brand" href="/">
                        <b className="logo-icon">
                        </b>
                        <span className="logo-text brand-text">
                          <b>Kite LIT</b>
                        </span>
                    </a>
                    <a className="nav-toggler waves-effect waves-light text-dark d-block d-md-none" href="javascript:void(0)"><i className="ti-menu ti-close"></i></a>
                </div>
                <div className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
                   
                    <ul className="navbar-nav mr-auto ">

                        <li className="nav-item">
                        <NavLink className="nav-link" to="/" element={<AlertElement />}>
                          <b>Metrics</b>
                        </NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" to="/alerts" element={<AlertElement />}>
                          <b>Alerts</b>
                        </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
            <div className="page-wrapper">
                <Routes>
                  <Route path="/" element={<WindList />} />
                  <Route path="/alerts" element={<AlertElement />} />
                </Routes>
            </div>
              </Router>
          </>
        </Protect>
        )   
    }   
}

export default App;
